import { newCustomerRouter } from '@/router/routePathName'

export default [
  // {
  //   path: newCustomerRouter.path,
  //   name: newCustomerRouter.name,
  //   component: () => import('@/views/User/User.vue'),
  //   meta: {
  //     auth: false,
  //     titlePage: newCustomerRouter.titlePage,
  //   },
  // },
]
