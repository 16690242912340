<template>
  <footer>
    <cookie-law theme="dark-lime">
      <div
        slot-scope="props"
        class="cookies"
      >
        <p>
          🍪 Ao utilizar a Lalak Doceria você concorda com o uso de cookies.
          Para mais informações leia nossos
          <b-link
            class="terms-of-use"
            @click="redirectToTermsOfUse"
          >
            Termos de Uso.
          </b-link>
        </p>

        <button-form
          :border-radius="0"
          :height="38"
          @action="props.accept"
        >
          <span>Concordo</span>
        </button-form>
      </div>

    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'
import { BLink } from 'bootstrap-vue'
import { termsOfUseRouter } from '@/router/routePathName'

export default {
  name: 'Cookies',

  components: {
    CookieLaw,
    ButtonForm,
    BLink,
  },

  methods: {
    redirectToTermsOfUse() {
      const route = this.$router.resolve({
        name: termsOfUseRouter.name,
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style scoped>
.cookies {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .cookies {
    max-width: 400px;
    flex-direction: column;
    margin: 0 auto;
  }
}
</style>
