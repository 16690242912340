import {
  loginRouter,
  forgotPasswordRouter,
  emailSentRouter,
  resetPasswordRouter,
  newCustomerRouter,
  error404Router,
  disconnectedRouter,
  unverifiedEmailRouter,
  myAccountRouter,
  termsOfUseRouter,
} from '@/router/routePathName'

const fullLayouts = () => [
  termsOfUseRouter.name,
  loginRouter.name,
  forgotPasswordRouter.name,
  emailSentRouter.name,
  resetPasswordRouter.name,
  newCustomerRouter.name,
  error404Router.name,
  disconnectedRouter.name,
  unverifiedEmailRouter.name,
  myAccountRouter.name,
]

export default fullLayouts()
