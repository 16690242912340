import Vue from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import VueMask, { VueMaskDirective } from 'v-mask'
import router from './router'
import store from './store'
import App from './App.vue'
import './assets/scss/base/global.scss'
import './global-components'
import '@/assets/scss/vue-select.scss'

Vue.use(VueMask)

Vue.directive('mask', VueMaskDirective)

Vue.directive('scroll', {
  inserted(el, binding) {
    const f = evt => {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
