import { contactRouter } from '@/router/routePathName'

export default [
  {
    path: contactRouter.path,
    name: contactRouter.name,
    component: () => import('@/views/Contact/Contact.vue'),
    meta: {
      auth: false,
      titlePage: contactRouter.titlePage,
    },
  },
]
