import provider from '@/store'
import axiosIns from '@/libs/axios'
import apiRoutes from '@/router/apiRoutes'
import {appURL} from "@/libs/api";

const login = `${appURL}/login`
const notAuthorized = `${appURL}/nao-autorizado`

export const clearStore = () => {
  provider.dispatch('sessions/logout').then()
}

export const isUserLoggedIn = () => provider.getters['sessions/isLoggedIn']

export const getToken = () => provider.state.sessions.token

export const getUserData = () => provider.state.sessions.userData

export const startCount = () => {
  provider.dispatch('sessions/startCount').then()
}

export const redirectToNotAuthorized = () => {
  location.href = notAuthorized
}

export const logoutUser = () => new Promise((resolve, reject) => {
  axiosIns.get(apiRoutes.logout)
    .then(response => {
      clearStore()
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const logoutUserRedirectToLogin = () => {
  clearStore()
  location.href = login
}
