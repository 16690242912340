import Vue from 'vue'
import VueRouter from 'vue-router'
import { clearStore, isUserLoggedIn } from '@/auth/utils'
import { loginRouter, homeRouter, error404Router } from '@/router/routePathName'

import setStyleHeader from '@/assets/js/header'
import auth from './routes/auth'
import misc from './routes/misc'
import home from './routes/home'
import products from './routes/products'
import about from './routes/about'
import contact from './routes/contact'
import customers from './routes/customers'
import myAccount from './routes/myAccount'
import support from './routes/support'
import termsOfUse from './routes/termsOfUse'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: homeRouter.name } },
    ...auth,
    ...misc,
    ...home,
    ...products,
    ...about,
    ...contact,
    ...customers,
    ...myAccount,
    ...support,
    ...termsOfUse,
    {
      path: '*',
      redirect: { name: error404Router.name },
    },
  ],
})

router.beforeEach((routerTo, routerFrom, next) => {
  const isLoggedIn = isUserLoggedIn()

  if ((routerTo.name === loginRouter.name) && isLoggedIn) {
    setStyleHeader(
      homeRouter.name,
      homeRouter.titlePage,
    )

    return next({ name: homeRouter.name })
  }

  if (routerTo.meta.auth && !isLoggedIn) {
    clearStore()

    setStyleHeader(
      loginRouter.name,
      loginRouter.titlePage,
    )

    return next({ name: loginRouter.name })
  }

  setStyleHeader(
    routerTo.name,
    routerTo.meta.titlePage,
  )

  return next()
})

export default router
