import { myAccountRouter } from '@/router/routePathName'

export default [
  // {
  //   path: myAccountRouter.path,
  //   name: myAccountRouter.name,
  //   component: () => import('@/views/MyAccount/MyAccount.vue'),
  //   meta: {
  //     auth: true,
  //     titlePage: myAccountRouter.titlePage,
  //   },
  // },
]
