<template>
  <button
    :disabled="getDisabledButton"
    :class="getComponentMode"
    :style="getStyles"
    @click.prevent="handleActionButton"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonForm',

  props: {
    componentMode: {
      type: String,
      default: 'background',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    textButton: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 35,
    },
    lineHeight: {
      type: Number,
      default: 31,
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getComponentMode() {
      if (this.componentMode === 'outline') {
        return `btn-outline-form ${this.buttonClass}`
      }
      return `btn-form ${this.buttonClass}`
    },

    getStyles() {
      return {
        width: this.width > 0 ? `${this.width}%` : 'auto',
        height: `${this.height}px`,
        lineHeight: `${this.lineHeight}px`,
        borderRadius: this.rounded ? `${22}px` : `${this.borderRadius}px`,
      }
    },

    getDisabledButton() {
      return this.disabledButton
    },
  },

  methods: {
    handleActionButton() {
      this.$emit('action')
    },
  },
}
</script>

<style scoped>
.btn-form {
  text-decoration: none;
  background: #f19596;
  color: #fff;
  border: 2px solid #f19596;
  line-height: 42px;
  display: inline-block;
  padding: 0 32px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  position: relative;
  overflow: hidden;
  font-weight: 600;
}

.btn-form:hover {
  background: #f19596;
  color: #fff;
}

.btn-form:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
  -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

.btn-form:hover:before {
  left: 120%;
  -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn-outline-form {
  text-decoration: none;
  height: 45px;
  background: transparent;
  color: #f19596;
  border: 2px solid #f19596;
  line-height: 41px;
  display: inline-block;
  padding: 0 32px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  position: relative;
  overflow: hidden;
  font-weight: 600;
  transition: .3s background-color;
}

.btn-outline-form:hover {
  background: transparent;
  color: #f19596;
  border: 2px solid #f19596;
  text-decoration: underline;
}

@media (max-width: 350px) {
  .btn-form {
    width: 100% !important;
  }

  .btn-outline-form {
    width: 100% !important;
  }
}
</style>
