<template>
  <div
    id="app"
  >
    <component :is="layout">
      <router-view />
    </component>

    <cookies />
  </div>
</template>

<script>
import { logoutUser, startCount } from '@/auth/utils'
import { disconnectedRouter } from '@/router/routePathName'
import fullLayouts from '@/assets/js/fullLayouts'
import Cookies from '@/views/Layouts/Cookies.vue'

const FullLayout = () => import('@/views/Layouts/FullLayout.vue')
const Index = () => import('@/views/Layouts/Index.vue')

export default {
  name: 'App',

  components: {
    FullLayout,
    Index,
    Cookies,
  },

  data() {
    return {
      disconnectedRouter,
      fullLayouts,
    }
  },

  computed: {
    layout() {
      if (this.fullLayouts.includes(this.$route.name)) {
        return 'index'
      }

      return 'full-layout'
    },
  },

  mounted() {
    this.startSessionTime()
  },

  methods: {
    startSessionTime() {
      startCount()

      this.$store.watch(this.$store.getters['sessions/getCount'], c => {
        this.$store.dispatch('sessions/setCount', c)

        if (c === 0) {
          logoutUser()
            .then(() => {
              this.$router.push({ name: disconnectedRouter.name })
            })
            .catch(() => {
              this.$router.push({ name: disconnectedRouter.name })
            })
        }
      })
    },
  },
}
</script>
