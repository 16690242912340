/* eslint-disable no-shadow */
import api from '@/libs/api'
import apiRoutes from '@/router/apiRoutes'

const state = {
  systemData: {
    id: '',
    phone: '',
    cellPhone: '',
    email: '',
    zipCode: '',
    address: '',
    numberAddress: '',
    complement: '',
    district: '',
    city: '',
    uf: '',
    facebook: '',
    instagram: '',
    pinterest: '',
    about: '',
  },
}

const mutations = {
  defineSystemData(state, systemData) {
    state.systemData = systemData
  },
}

const actions = {
  setSystemData({ commit }) {
    commit(
        'defineSystemData',
        {
          id: '',
          phone: '5130661147',
          cellPhone: '5198701147',
          email: 'contato@lalakdoceria.com.br',
          zipCode: '93310002',
          address: 'Rua Joaquim Nabuco',
          numberAddress: '1685',
          complement: 'Sala 04',
          district: 'Centro',
          city: 'Novo Hamburgo',
          uf: 'RS',
          facebook: 'https://www.facebook.com/lalakdoceriaartesanal',
          instagram: 'https://www.instagram.com/lalakdoceriaartesanal/',
          pinterest: 'https://br.pinterest.com/contato7332/',
          about: 'Em busca de um novo projeto, surgiu a Lala-K. O que era um Hobby deu início a um doce negócio. Iniciou com cookies artesanais para uma festa familiar, despertando um interesse maior pela confeitaria e posteriormente por doces, bolos e com isso também, a paixão pela modelagem em pasta americana. Todos os produtos da Lala-K são preparados com ingredientes selecionados e de altíssima qualidade. Somente o que há de melhor no mercado é adicionado aos bolos e doces. Aqui na Lala-K transformamos a imaginação em encantadoras obras de arte. Prepare-se para uma overdose de doçuras!',
        }
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
