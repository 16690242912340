import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import header from '@/store/header'
import sessions from '@/store/sessions'
import chooseData from '@/store/chooseData'
import system from '@/store/system'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    header,
    sessions,
    chooseData,
    system,
  },
  plugins: [
    createPersistedState({
      paths: [
        'header',
        'sessions',
        'chooseData',
        'system',
      ],
    }),
  ],
  strict: process.env.DEV,
})
