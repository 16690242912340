import { termsOfUseRouter } from '@/router/routePathName'

export default [
  {
    path: termsOfUseRouter.path,
    name: termsOfUseRouter.name,
    component: () => import('@/views/TermsOfUse/TermsOfUse.vue'),
    meta: {
      auth: false,
      titlePage: termsOfUseRouter.titlePage,
    },
  },
]
