import {
  productsRouter,
  productDetailsRouter,
  specialProductsRouter,
  favoriteProductsRouter,
} from '@/router/routePathName'

export default [
  {
    path: productsRouter.path,
    name: productsRouter.name,
    component: () => import('@/views/Products/Products.vue'),
    meta: {
      auth: false,
      titlePage: productsRouter.titlePage,
    },
  },

  {
    path: `${productDetailsRouter.path}/:productUniqueName`,
    name: productDetailsRouter.name,
    component: () => import('@/views/ProductDetails/ProductDetails.vue'),
    props: true,
    meta: {
      auth: false,
      titlePage: productDetailsRouter.titlePage,
    },
  },

  {
    path: specialProductsRouter.path,
    name: specialProductsRouter.name,
    component: () => import('@/views/SpecialProducts/SpecialProducts.vue'),
    meta: {
      auth: false,
      titlePage: specialProductsRouter.titlePage,
    },
  },

  {
    path: favoriteProductsRouter.path,
    name: favoriteProductsRouter.name,
    component: () => import('@/views/UserFavoriteProducts/UserFavoriteProducts.vue'),
    meta: {
      auth: true,
      titlePage: favoriteProductsRouter.titlePage,
    },
  },
]
