import {
  loginRouter,
  forgotPasswordRouter,
  emailSentRouter,
  resetPasswordRouter,
} from '@/router/routePathName'

export default [
  // {
  //   path: loginRouter.path,
  //   name: loginRouter.name,
  //   component: () => import('@/views/Authentication/Login.vue'),
  //   meta: {
  //     auth: false,
  //     titlePage: loginRouter.titlePage,
  //   },
  // },
  //
  // {
  //   path: forgotPasswordRouter.path,
  //   name: forgotPasswordRouter.name,
  //   component: () => import('@/views/Authentication/ForgotPassword.vue'),
  //   meta: {
  //     auth: false,
  //     titlePage: forgotPasswordRouter.titlePage,
  //   },
  // },
  //
  // {
  //   path: `${emailSentRouter.path}/:email`,
  //   name: emailSentRouter.name,
  //   component: () => import('@/views/Authentication/EmailSent.vue'),
  //   props: true,
  //   meta: {
  //     auth: false,
  //     titlePage: emailSentRouter.titlePage,
  //   },
  // },
  //
  // {
  //   path: `${resetPasswordRouter.path}/:code`,
  //   name: resetPasswordRouter.name,
  //   component: () => import('@/views/Authentication/ResetPassword.vue'),
  //   props: true,
  //   meta: {
  //     auth: false,
  //     titlePage: resetPasswordRouter.titlePage,
  //   },
  // },
]
