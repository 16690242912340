import { aboutRouter } from '@/router/routePathName'

export default [
  {
    path: aboutRouter.path,
    name: aboutRouter.name,
    component: () => import('@/views/About/About.vue'),
    meta: {
      auth: false,
      titlePage: aboutRouter.titlePage,
    },
  },
]
