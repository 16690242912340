import {
  error404Router,
  disconnectedRouter,
  unverifiedEmailRouter,
} from '@/router/routePathName'

export default [
  {
    path: error404Router.path,
    name: error404Router.name,
    component: () => import('@/views/Errors/Error404.vue'),
    meta: {
      auth: false,
      titlePage: error404Router.titlePage,
    },
  },

  {
    path: disconnectedRouter.path,
    name: disconnectedRouter.name,
    component: () => import('@/views/Miscellaneous/Disconnected.vue'),
    meta: {
      auth: false,
      titlePage: disconnectedRouter.titlePage,
    },
  },

  {
    path: unverifiedEmailRouter.path,
    name: unverifiedEmailRouter.name,
    component: () => import('@/views/Miscellaneous/UnverifiedEmail.vue'),
    meta: {
      auth: false,
      titlePage: unverifiedEmailRouter.titlePage,
    },
  },
]
