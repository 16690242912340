import axios from 'axios'

export const apiURL = process.env.VUE_APP_API_URL
export const appURL = 'https://lalakdoceria.com.br'

const api = axios.create({
  headers: {
    Accept: 'application/json',
    Content: 'application/json',
  },
  baseURL: `${apiURL}/api`,
})
export default api
