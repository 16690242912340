import { supportRouter } from '@/router/routePathName'

export default [
  {
    path: supportRouter.path,
    name: supportRouter.name,
    component: () => import('@/views/Support/Support.vue'),
    meta: {
      auth: false,
      titlePage: supportRouter.titlePage,
    },
  },
]
