const apiRoutes = {
  login: '/auth/login',
  logout: '/auth/logout',

  forgotPassword: 'auth/password/forgot',
  resetPassword: code => `auth/password/reset/${code}`,

  themes: '/themes',
  categories: themeId => `/categories/theme/${themeId}`,
  events: '/events',
  products: '/products',
  productId: id => `/products/id/${id}`,
  productUniqueName: uniqueName => `/products/unique-name/${uniqueName}`,
  highlightProducts: '/products/highlight',

  userFavoriteProducts: '/favorite-products',

  customers: '/customers',
  customersAuthorize: id => `/customers/authorize/${id}`,
  customerId: '/customers/my-account',
  customerEmailExists: email => `/customers/email/exists/${email}`,

  citiesByUf: uf => `/cities/uf/${uf}`,
  citiesById: id => `/cities/id/${id}`,
  citiesInPersons: '/cities/in-persons',

  zipCode: zip => `/zip-code?zipCode=${zip}`,

  system: '/system',
  support: '/support',
}

export default apiRoutes
