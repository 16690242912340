export const homeRouter = {
  path: '/',
  name: 'home',
  titlePage: '',
}

export const loginRouter = {
  path: '/login',
  name: 'auth-login',
  titlePage: '',
}

export const forgotPasswordRouter = {
  path: '/esqueci-minha-senha',
  name: 'auth-forgot-password',
  titlePage: '',
}

export const emailSentRouter = {
  path: '/esqueci-minha-senha/verificar-email',
  name: 'auth-verify-email',
  titlePage: '',
}

export const resetPasswordRouter = {
  path: '/esqueci-minha-senha/nova-senha',
  name: 'auth-reset-password',
  titlePage: '',
}

export const error404Router = {
  path: '/pagina-nao-encontrada',
  name: 'error-404',
  titlePage: '',
}

export const unverifiedEmailRouter = {
  path: '/email-nao-verificado',
  name: 'unverified-email',
  titlePage: '',
}

export const disconnectedRouter = {
  path: '/sessao-expirada',
  name: 'disconnected',
  titlePage: '',
}

export const newCustomerRouter = {
  path: '/cadastrar-usuario',
  name: 'new-customer',
  titlePage: 'Cadastro',
}

export const productsRouter = {
  path: '/produtos',
  name: 'products',
  titlePage: 'Produtos',
}

export const productDetailsRouter = {
  path: '/produtos/detalhes',
  name: 'product-details',
  titlePage: 'Detalhes',
}

export const specialProductsRouter = {
  path: '/produtos/especial',
  name: 'special-products',
  titlePage: 'Especial',
}

export const aboutRouter = {
  path: '/sobre',
  name: 'about',
  titlePage: 'Sobre',
}

export const contactRouter = {
  path: '/contato',
  name: 'contact',
  titlePage: 'Contato',
}

export const myAccountRouter = {
  path: '/minha-conta',
  name: 'my-account',
  titlePage: '',
}

export const favoriteProductsRouter = {
  path: '/produtos-favoritos',
  name: 'favorite-products',
  titlePage: 'Favoritos',
}

export const supportRouter = {
  path: '/ajuda-e-suporte',
  name: 'support',
  titlePage: 'Ajuda e Suporte',
}

export const termsOfUseRouter = {
  path: '/termos-de-uso',
  name: 'termsOfUse',
  titlePage: 'Termos de Uso',
}
