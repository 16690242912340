import { homeRouter } from '@/router/routePathName'

export default [
  {
    path: homeRouter.path,
    name: homeRouter.name,
    component: () => import('@/views/Home/Home.vue'),
    meta: {
      auth: false,
      titlePage: homeRouter.titlePage,
    },
  },
]
