/* eslint-disable no-shadow */
const state = {
  chooseProduct: null,
  emailForgotPassword: '',
}

const mutations = {
  setChooseProduct(state, itemData) {
    state.chooseProduct = itemData
  },

  setEmailForgotPassword(state, itemData) {
    state.emailForgotPassword = itemData
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
