import store from '@/store'

const setStyleHeader = (name, titlePage) => {
  store.commit('header/setStyleHeader', {
    name,
    titlePage,
  })
}

export default setStyleHeader
