import { homeRouter } from '@/router/routePathName'

/* eslint-disable no-shadow */
const state = {
  promoSection: {
    height: `${100}vh`,
    width: `${100}%`,
    position: 'relative',
    color: '#fff',
    background: '#101113',
  },

  carouselInner: {
    position: 'relative',
    width: `${100}%`,
    overflow: 'hidden',
  },

  titlePage: '',
}

const mutations = {
  setStyleHeader(state, itemData) {
    state.titlePage = itemData.titlePage

    if (itemData.name === homeRouter.name) {
      state.promoSection = {
        height: `${100}vh`,
        width: `${100}%`,
        position: 'relative',
        color: '#fff',
        background: '#101113',
      }
      state.carouselInner = {
        position: 'relative',
        width: `${100}%`,
        overflow: 'hidden',
      }
    } else {
      state.promoSection = {
        height: `${55}vh`,
        width: `${100}%`,
        position: 'relative',
        color: '#fff',
        background: '#101113',
      }
      state.carouselInner = {
        position: 'relative',
        width: `${100}%`,
        height: `${55}vh`,
        overflow: 'hidden',
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
